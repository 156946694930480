import React, { useEffect, useState } from "react";
import OpenOrderSection from "../OpenOrderSection";

import { OPEN_ORDERS_URL } from "../../constants/urls";

import { Order } from "binance-api-node";

type Props = {
  onOrderCancelled: () => void;
};

const OpenOrdersContent = ({ onOrderCancelled }: Props) => {
  const [openOrders, setOpenOrders] = useState<Order[]>([]);

  const fetchOpenOrders = async () => {
    const resp = await fetch(OPEN_ORDERS_URL);
    const data = await resp.json();

    setOpenOrders(data);
  };

  useEffect(() => {
    fetchOpenOrders();
  }, []);

  const internalOnOrderCancelled = () => {
    fetchOpenOrders();
    onOrderCancelled();
  };

  return (
    <>
      <div className="w-full px-[32px] py-[43px]">
        <OpenOrderSection
          openOrders={openOrders}
          title={
            <div className="text-3xl font-medium mb-[30px]">Open Orders</div>
          }
          height={350}
          token1={""}
          symbol={""}
          onOrderCancelled={internalOnOrderCancelled}
        />
      </div>
    </>
  );
};

export default OpenOrdersContent;
