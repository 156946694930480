const tokenPairsMapping = {
  BTC: [
    "AAVE",
    "ALPHA",
    "ANKR",
    "APE",
    "APT",
    "ARB",
    "ATOM",
    "AUDIO",
    "AVAX",
    "AXS",
    "BAND",
    "BCH",
    "BICO",
    "BNB",
    "CAKE",
    "COMP",
    "DIA",
    "DOGE",
    "DOT",
    "EGLD",
    "ENS",
    "EOS",
    "ETH",
    "FIL",
    "FTM",
    "GALA",
    "GMT",
    "GMX",
    "KDA",
    "KLAY",
    "LDO",
    "LINK",
    "LTC",
    "MINA",
    "NEO",
    "ONE",
    "OP",
    "PAXG",
    "PYR",
    "ROSE",
    "SAND",
    "SKL",
    "SOL",
    "STG",
    "SUI",
    "SYN",
    "TLM",
    "TRX",
    "WAVES",
    "WBTC",
    "WLD",
    "WOO",
    "XLM",
    "XMR",
    "XRP",
    "XTZ",
    "YFI",
  ],
  ETH: [
    "AAVE",
    "APE",
    "ATOM",
    "AXS",
    "BNB",
    "EOS",
    "GALA",
    "GMT",
    "LINK",
    "LTC",
    "NEO",
    "ROSE",
    "SAND",
    "SLP",
    "TRX",
    "WAVES",
    "WBTC",
    "XLM",
    "XMR",
    "XRP",
  ],
  BNB: [
    "AAVE",
    // "ANKR",
    "APE",
    "ATOM",
    "AVAX",
    "AXS",
    "BAKE",
    "BCH",
    "CAKE",
    "DOT",
    "EGLD",
    "ENS",
    // "EOS",
    "FIL",
    "FTM",
    "GALA",
    "GMT",
    "LTC",
    // "NEO",
    "ONE",
    "PAXG",
    "SAND",
    "SOL",
    "SUI",
    "TRX",
    "WOO",
    "XLM",
    "XMR",
    "XRP",
  ],
  USDT: [
    "ADA",
    "APE",
    "APT",
    "ARB",
    "ARKM",
    "BNB",
    "BTC",
    "BUSD",
    "CYBER",
    "DOGE",
    "ETH",
    "FLOKI",
    "FTM",
    "GALA",
    "GMT",
    "JOE",
    "LTC",
    "MANA",
    "MATIC",
    "PAXG",
    "PEPE",
    "SAND",
    "SEI",
    "SHIB",
    "SLP",
    "SOL",
    "SUI",
    "WLD",
    "XRP",
  ],
};

const CURRENTLY_SUPPORTED_NETWORK = ["BSC", "ETH"];
const DEFAULT_DEPOSIT_COIN = "BTC";
const DEFAULT_WITHDRAW_COIN = "BTC";

export {
  tokenPairsMapping,
  CURRENTLY_SUPPORTED_NETWORK,
  DEFAULT_DEPOSIT_COIN,
  DEFAULT_WITHDRAW_COIN,
};
