const mockCryptoBalanceList = [
  {
    coinSymbol: "BTC",
    coinImageUrl: "https://example.com/bitcoin.png",
    coinName: "Bitcoin",
    coinTotalBalance: "5.789",
    coinAvailableBalance: "4.567",
    coinInOrder: "1.222",
    coinBtcValue: "0.128",
  },
  {
    coinSymbol: "ETH",
    coinImageUrl: "https://example.com/ethereum.png",
    coinName: "Ethereum",
    coinTotalBalance: "32.456",
    coinAvailableBalance: "28.123",
    coinInOrder: "4.333",
    coinBtcValue: "1.987",
  },
  {
    coinSymbol: "XRP",
    coinImageUrl: "https://example.com/xrp.png",
    coinName: "XRP",
    coinTotalBalance: "1000.00",
    coinAvailableBalance: "500.00",
    coinInOrder: "500.00",
    coinBtcValue: "0.005",
  },
  {
    coinSymbol: "LTC",
    coinImageUrl: "https://example.com/litecoin.png",
    coinName: "Litecoin",
    coinTotalBalance: "87.654",
    coinAvailableBalance: "75.432",
    coinInOrder: "12.222",
    coinBtcValue: "0.652",
  },
  {
    coinSymbol: "BCH",
    coinImageUrl: "https://example.com/bitcoincash.png",
    coinName: "Bitcoin Cash",
    coinTotalBalance: "20.123",
    coinAvailableBalance: "18.789",
    coinInOrder: "1.334",
    coinBtcValue: "0.256",
  },
  {
    coinSymbol: "ADA",
    coinImageUrl: "https://example.com/cardano.png",
    coinName: "Cardano",
    coinTotalBalance: "5000.00",
    coinAvailableBalance: "3000.00",
    coinInOrder: "2000.00",
    coinBtcValue: "0.012",
  },
  {
    coinSymbol: "DOT",
    coinImageUrl: "https://example.com/polkadot.png",
    coinName: "Polkadot",
    coinTotalBalance: "100.00",
    coinAvailableBalance: "90.00",
    coinInOrder: "10.00",
    coinBtcValue: "0.001",
  },
  {
    coinSymbol: "LINK",
    coinImageUrl: "https://example.com/chainlink.png",
    coinName: "Chainlink",
    coinTotalBalance: "750.00",
    coinAvailableBalance: "600.00",
    coinInOrder: "150.00",
    coinBtcValue: "0.009",
  },
  {
    coinSymbol: "XLM",
    coinImageUrl: "https://example.com/stellar.png",
    coinName: "Stellar",
    coinTotalBalance: "12000.00",
    coinAvailableBalance: "10000.00",
    coinInOrder: "2000.00",
    coinBtcValue: "0.021",
  },
  {
    coinSymbol: "DOGE",
    coinImageUrl: "https://example.com/dogecoin.png",
    coinName: "Dogecoin",
    coinTotalBalance: "150000.00",
    coinAvailableBalance: "130000.00",
    coinInOrder: "20000.00",
    coinBtcValue: "0.003",
  },
  {
    coinSymbol: "XMR",
    coinImageUrl: "https://example.com/monero.png",
    coinName: "Monero",
    coinTotalBalance: "180.00",
    coinAvailableBalance: "160.00",
    coinInOrder: "20.00",
    coinBtcValue: "0.007",
  },
  {
    coinSymbol: "UNI",
    coinImageUrl: "https://example.com/uniswap.png",
    coinName: "Uniswap",
    coinTotalBalance: "80.00",
    coinAvailableBalance: "70.00",
    coinInOrder: "10.00",
    coinBtcValue: "0.002",
  },
  {
    coinSymbol: "USDT",
    coinImageUrl: "https://example.com/tether.png",
    coinName: "Tether",
    coinTotalBalance: "50000.00",
    coinAvailableBalance: "49000.00",
    coinInOrder: "1000.00",
    coinBtcValue: "0.545",
  },
  {
    coinSymbol: "AAVE",
    coinImageUrl: "https://example.com/aave.png",
    coinName: "Aave",
    coinTotalBalance: "25.00",
    coinAvailableBalance: "20.00",
    coinInOrder: "5.00",
    coinBtcValue: "0.001",
  },
  {
    coinSymbol: "XEM",
    coinImageUrl: "https://example.com/nem.png",
    coinName: "NEM",
    coinTotalBalance: "22000.00",
    coinAvailableBalance: "21000.00",
    coinInOrder: "1000.00",
    coinBtcValue: "0.018",
  },
  {
    coinSymbol: "ATOM",
    coinImageUrl: "https://example.com/cosmos.png",
    coinName: "Cosmos",
    coinTotalBalance: "150.00",
    coinAvailableBalance: "140.00",
    coinInOrder: "10.00",
    coinBtcValue: "0.005",
  },
  {
    coinSymbol: "ICX",
    coinImageUrl: "https://example.com/icon.png",
    coinName: "ICON",
    coinTotalBalance: "500.00",
    coinAvailableBalance: "400.00",
    coinInOrder: "100.00",
    coinBtcValue: "0.004",
  },
  {
    coinSymbol: "VET",
    coinImageUrl: "https://example.com/vechain.png",
    coinName: "VeChain",
    coinTotalBalance: "8000.00",
    coinAvailableBalance: "7000.00",
    coinInOrder: "1000.00",
    coinBtcValue: "0.024",
  },
  {
    coinSymbol: "THETA",
    coinImageUrl: "https://example.com/theta.png",
    coinName: "Theta",
    coinTotalBalance: "400.00",
    coinAvailableBalance: "380.00",
    coinInOrder: "20.00",
    coinBtcValue: "0.006",
  },
  {
    coinSymbol: "FIL",
    coinImageUrl: "https://example.com/filecoin.png",
    coinName: "Filecoin",
    coinTotalBalance: "1800.00",
    coinAvailableBalance: "1600.00",
    coinInOrder: "200.00",
    coinBtcValue: "0.032",
  },
];

const withdrawDepositHistoryList = [
  {
    status: "Success",
    coinSymbol: "USDT sample",
    amount: "11.01827819",
    time: 1693560718,
    Information: "Address: 0xc421bea026025ea8f8576a2cf56e12b2e6e4a7fe",
  },
  {
    status: "Success",
    coinSymbol: "USDC sample",
    amount: "11.01827819",
    time: 1693560718,
    Information: "Address: 0xc421bea026025ea8f8576a2cf56e12b2e6e4a7fe",
  },
  {
    status: "Success",
    coinSymbol: "USDC sample",
    amount: "11.01827819",
    time: 1693560718,
    Information: "Address: 0xc421bea026025ea8f8576a2cf56e12b2e6e4a7fe",
  },
];

const mockOpenOrders = [
  {
    date: 1231231320,
    symbol: "ETHDCA",
    type: "LIMIT",
    side: "Buy",
    price: "0.123123",
    origQty: "0.123123",
    executedQty: "0.11",
  },
  {
    date: 1231231320,
    symbol: "ETHDCA",
    type: "LIMIT",
    side: "Buy",
    price: "0.123123",
    origQty: "0.123123",
    executedQty: "0.11",
  },
  {
    date: 1231231320,
    symbol: "ETHDCA",
    type: "LIMIT",
    side: "Buy",
    price: "0.123123",
    origQty: "0.123123",
    executedQty: "0.11",
  },
  {
    date: 1231231320,
    symbol: "ETHDCA",
    type: "LIMIT",
    side: "Buy",
    price: "0.123123",
    origQty: "0.123123",
    executedQty: "0.11",
  },
];

export { mockCryptoBalanceList, withdrawDepositHistoryList, mockOpenOrders };
