import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Homepage from "./pages/home.js";
import Dashboard from "./pages/dashboard.js";

import AuthProvider from "./provider/authProvider";
import Routes from "./routes/index.jsx";

export default function App() {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}
