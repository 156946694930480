import React from "react";
import EmptyImage from "../assets/no_record_image.png";
import moment from "moment";
import { OrderHistoryDetail } from "../types/types";

type Props = {
  orderHistoryList: OrderHistoryDetail[];
  title: React.ReactNode;
  height: number;
};

const MyOrderHistorySection = React.memo(
  ({ orderHistoryList, title, height }: Props) => {
    return (
      <div className="flex flex-col" style={{ height: height }}>
        {title}

        <div
          className="border-normalBorder border-[1px] h-[267px] flex flex-col flex-1"
          style={{ overflow: "scroll" }}
        >
          <div className="" style={{ overflow: "scroll" }}>
            <table className="flex-1 w-full" style={{ position: "sticky" }}>
              <thead
                className="bg-backgroundGray border-normalBorder"
                style={{ position: "sticky" }}
              >
                <tr className="text-normalGray text-semibold text-xs text-left ">
                  <th className="py-[5px] px-[6px] w-[124.1px] font-medium">{`Time`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[10px] px-[6px] w-[124.1px] font-medium">{`Pair`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[124.1px] font-medium">{`Type`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[72.8px]] font-medium">{`Side`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[124.1px] font-medium">{`Average`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[124.1px] font-medium">{`Price`}</th>
                  <th className="border-l-[1px] border-normalBorder  py-[5px] px-[6px] w-[124.1px] font-medium">{`Filled`}</th>
                  <th className="border-l-[1px] border-normalBorder  py-[5px] px-[6px] w-[124.1px] font-medium">{`Amount`}</th>
                  <th className="border-l-[1px] border-normalBorder  py-[5px] px-[6px] w-[124.1px] font-medium">{`Total`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] w-[124.1px]] font-medium">{`Trigger conditions`}</th>
                  <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] font-medium">{`Status`}</th>
                </tr>
              </thead>
              {orderHistoryList.length > 0 && (
                <tbody
                  className="border-collapse w-full"
                  style={{ overflow: "scroll" }}
                >
                  {orderHistoryList.map((orderHistory) => {
                    const {
                      type,
                      side,
                      average,
                      price,
                      total,
                      triggerConditions,
                      status,
                      time,
                      symbol,
                      executedQty,
                      origQty,
                      origQuoteOrderQty,
                    } = orderHistory;

                    const momentTimestamp = moment(time);
                    let strTime = momentTimestamp.format("MM-DD HH:mm:ss");

                    const totalQty =
                      type === "LIMIT"
                        ? parseFloat(executedQty) * parseFloat(price)
                        : origQuoteOrderQty;

                    const avgPrice = type === "LIMIT" ? price : "-";

                    return (
                      <tr className="text-xs text-left">
                        <th className="py-[10px] px-[4px] font-medium">{`${strTime}`}</th>
                        <th className="px-[4px] font-medium">{`${symbol}`}</th>
                        <th className="px-[4px] font-medium">{`${type}`}</th>
                        <th
                          className={`px-[4px] font-medium ${
                            side == "BUY"
                              ? "text-normalGreen"
                              : "text-normalRed"
                          }`}
                        >{`${side}`}</th>
                        <th className="px-[4px] font-medium">{`${
                          status === "FILLED" ? avgPrice : "-"
                        }`}</th>
                        <th className="px-[4px] font-medium text-textGray">{`${
                          status === "FILLED" ? "-" : price
                        }`}</th>
                        <th className="px-[4px] font-medium">{`${
                          status === "FILLED" ? executedQty : "-"
                        }`}</th>
                        <th className="px-[4px] font-medium text-textGray">{`${origQty}`}</th>

                        <th className="px-[4px] font-medium">{`${
                          status === "FILLED" ? totalQty : "-"
                        }`}</th>
                        <th className="px-[4px] font-medium">{`-`}</th>
                        <th className="px-[4px] font-medium">{`${status}`}</th>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>

          {orderHistoryList.length === 0 && (
            <div className="flex justify-center flex-1">
              <div className="flex flex-col justify-center align-center">
                <div className="flex justify-center">
                  <img src={EmptyImage} alt="empty image" />
                </div>
                <div>No records found</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default MyOrderHistorySection;
