import moment from "moment";
import React from "react";

type Props = {
  histories: [TxHistory];
};

type TxHistory = {
  qty: string;
  price: string;
  time: number;
};

const TradeHistory = React.memo(({ histories }: Props) => {
  return (
    <div
      className="relative max-h-[410px] border-2 border-normalBorder"
      style={{ overflow: "scroll" }}
    >
      <table
        className="border-2 border-normalBorder"
        style={{ overflow: "scroll" }}
      ></table>
      {histories.map(({ qty, price, time }, index) => {
        const momentTimestamp = moment(time);
        let strTime = momentTimestamp.format("MM-DD HH:mm:ss");

        let isCurrentPriceDecrease = false;
        if (index !== histories.length - 1) {
          let previousHistory = histories[index + 1];
          isCurrentPriceDecrease = price < previousHistory.price;
        }

        return (
          <tr className="text-xs text-normalBlack ">
            <th
              className={`${
                isCurrentPriceDecrease ? "text-normalRed" : "text-normalGreen"
              }`}
            >
              {price}
            </th>
            <th className="text-right font-medium w-[93px]">{qty}</th>
            <th className="text-right font-medium w-[100px]">{strTime}</th>
          </tr>
        );
      })}
    </div>
  );
});

export default TradeHistory;
