import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
// import Homepage from "./pages/home.js";
// import Dashboard from "./pages/dashboard.js";

import Homepage from "../pages/home.js";
import Dashboard from "../pages/dashboard.js";

import HeaderNew from "../pages/HeaderNew.tsx";
import Footer from "../pages/Footer.tsx";
import LoginPage from "../pages/login";
import RegisterPage from "../pages/register";
import MainPage from "../pages/MainPage";

const ComponentWithHeaderFooter = (children) => {
  return (
    <>
      <HeaderNew></HeaderNew>
      {children}
      <Footer />
    </>
  );
};

const Routes = () => {
  const { isUserAuthenticated } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/",
      element: ComponentWithHeaderFooter(<Homepage />),
    },

    {
      path: "/home",
      element: ComponentWithHeaderFooter(<MainPage />),
    },
    {
      path: "/register",
      element: ComponentWithHeaderFooter(<RegisterPage />),
    },
    {
      path: "/login",
      element: ComponentWithHeaderFooter(<LoginPage />),
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/dashboard",
          element: ComponentWithHeaderFooter(<Dashboard />),
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [];

  console.log("router here authenticate ", isUserAuthenticated);

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!isUserAuthenticated ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
