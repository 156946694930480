import { CURRENTLY_SUPPORTED_NETWORK } from "../constants/constants";
import { NetworkListDetail } from "../types/types";

const filterOptions = (options: NetworkListDetail[]): NetworkListDetail[] => {
  return options.filter((option) =>
    CURRENTLY_SUPPORTED_NETWORK.includes(option.network)
  );
};

export { filterOptions };
