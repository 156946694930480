import React, { useEffect, useState } from "react";

import { CChart } from "@coreui/react-chartjs";
import { BalanceDetail, UserAssetDetail } from "../../types/types";
import { USER_ASSETS_URL } from "../../constants/urls";

type Props = {
  email: string;
  userId: string;
  lastLogin: number;
  ipAddress: string;
  balances: BalanceDetail[];
  btcToUSDTConversion: number | null;
};

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
}

const options = {
  plugins: {
    legend: {
      labels: {
        generateLabels: (chart) => {
          return [];
        },
      },
    },
  },
  cutout: 70,
};

const DashboardContent = ({
  email,
  userId,
  lastLogin,
  ipAddress,
  btcToUSDTConversion,
}: Props) => {
  const [userAssets, setUserAssets] = useState<UserAssetDetail[]>([]);
  const labels = [];

  const fetchUserAsset = async () => {
    const resp = await fetch(USER_ASSETS_URL);
    const userAssets = await resp.json();

    setUserAssets(userAssets);
  };

  useEffect(() => {
    fetchUserAsset();
  }, []);

  const btcValuationBalance = userAssets.map((balance) =>
    parseFloat(balance.btcValuation)
  );

  const randomColors = userAssets.map(() => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  });

  const totalBtcBalance = userAssets.reduce(
    (acc, curVal) => acc + parseFloat(curVal.btcValuation),
    0
  );

  let totalUsdValues = btcToUSDTConversion
    ? btcToUSDTConversion * totalBtcBalance
    : 0;

  return (
    <div className="bg-backgroundGray w-full">
      <div className="px-[30px] py-[20px] bg-white">
        <div className="flex space-x-[15px]">
          <div>{email}</div>
          <div>
            <p className="inline">User ID: </p>
            {userId}
          </div>
        </div>
        <div>{`Last login ${timeConverter(lastLogin)} IP: ${ipAddress}`}</div>
      </div>
      <div className="bg-white mx-[15px] my-[15px] w-[850px] px-[16px] py-[25px]">
        <div className="font-semibold mb-[30px]">Balance Details</div>
        <div className="font-semibold">Exchange</div>
        <div className="border-b-[2px] border-brightGreen mt-[6px] w-[75px]"></div>
        <div className="border-b-[1px] border-normalBorder mt-[0.5px]"></div>
        <div className="flex py-[48px] justify-between items-center">
          <div className="">
            <div className="mb-[20px]">
              <div className="text-sm text-normalGray font-normal">
                Account Balance:
              </div>

              <div className="font-normal">
                <p className="text-3xl inline mr-[10px]">
                  {totalBtcBalance.toFixed(10)}
                </p>
                <p className="inline font-light">BTC</p>
              </div>
            </div>
            {btcToUSDTConversion ? (
              <div className="">
                <div className="text-normalGray text-sm">Estimated Value:</div>
                <div className="text-2xl">$ {totalUsdValues.toFixed(10)}</div>
              </div>
            ) : null}
          </div>
          <div className="px-[100px] flex">
            <div className="w-[200px] mr-[15px]">
              <CChart
                type="doughnut"
                data={{
                  labels: labels,
                  datasets: [
                    {
                      backgroundColor: randomColors,
                      data: btcValuationBalance,
                    },
                  ],
                }}
                options={options}
              />
            </div>
            <div>
              {userAssets.map((balance, i) => {
                return (
                  <div className="flex items-center space-x-[6px]">
                    <div
                      className="w-[6px] h-[6px]"
                      style={{
                        backgroundColor: randomColors[i],
                      }}
                    />
                    <div>
                      {balance.asset} {userAssets[i].btcValuation}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
