const PREFIX =
  process.env.REACT_APP_MODE === "DEBUG" ? "http://localhost:5001" : "";
const CANCEL_ORDER_URL = `${PREFIX}/cancel-order?`;
const CANCEL_ALL_ORDER_URL = `${PREFIX}/cancel-all-order?`;
const OPEN_ORDERS_URL = `${PREFIX}/open-orders`;
const MY_TRADES_URL = `${PREFIX}/my-trades?`;
const COINS_INFO_URL = `${PREFIX}/coin-infos`;
const ALL_ORDERS_URL = `${PREFIX}/all-orders?`;
const DEPOSIT_ADDRESS_URL = `${PREFIX}/deposit-address`;
const USER_ASSETS_URL = `${PREFIX}/user-assets`;
const ACCOUNT_INFORMATION_URL = `${PREFIX}/get-account-info`;
const NEW_ORDER_URL = `${PREFIX}/new-order`;
const DEPOSIT_HISTORY_URL = `${PREFIX}/deposit-history`;
const WITHDRAW_HISTORY_URL = `${PREFIX}/withdraw-history`;
const LOGIN_ACTION_URL = `${PREFIX}/api/users/login`;
const REGISTER_ACTION_URL = `${PREFIX}/api/users/signup`;
const PROTECTED_URL = `${PREFIX}/protected`;
const LOGOUT_URL = `${PREFIX}/logout`;
const IS_USER_AUTHENTICATED = `${PREFIX}/is-user-authenticated`;

export {
  CANCEL_ORDER_URL,
  CANCEL_ALL_ORDER_URL,
  OPEN_ORDERS_URL,
  MY_TRADES_URL,
  COINS_INFO_URL,
  ALL_ORDERS_URL,
  DEPOSIT_ADDRESS_URL,
  USER_ASSETS_URL,
  ACCOUNT_INFORMATION_URL,
  NEW_ORDER_URL,
  DEPOSIT_HISTORY_URL,
  WITHDRAW_HISTORY_URL,
  LOGIN_ACTION_URL,
  REGISTER_ACTION_URL,
  PROTECTED_URL,
  LOGOUT_URL,
  IS_USER_AUTHENTICATED,
};
