import { OrderSide_LT } from "binance-api-node";

enum BookOrderType {
  Ask,
  Bid,
}

type OrderHistoryDetail = {
  symbol: string;
  time: number;
  type: string;
  side: string;
  average: string;
  price: string;
  origQty: string;
  executedQty: string;
  origQuoteOrderQty: string;
  total: string;
  triggerConditions: string;
  status: string;
};

type TradeHistoryDetail = {
  date: number;
  pair: string;
  side: string;
  price: string;
  filled: string;
  tradingFees: string;
  total: string;
};

type CoinInfoDetail = {
  // value and label is the same as name in this case
  value: string;
  label: string;
  coin: string;
  depositAllEnable: boolean;
  name: string;
  withdrawAllEnable: boolean;
  networkList: NetworkListDetail[];
  free: string;
  locked: string;
  freeze: string;
  withdrawing: string;
  trading: boolean;
};

type NetworkListDetail = {
  coin: string;
  contractAddress: string;
  contractAddressUrl: string;
  depositEnable: boolean;
  withdrawEnable: boolean;
  network: string;
  name: string;
  withdrawFee: string;
  withdrawMin: string;
};

type DepositDetail = {
  coin: string;
  address: string;
  tag: string;
  url: string;
};

type UserAssetDetail = {
  asset: string; // for example AVAX
  free: string;
  locked: string;
  freeze: string;
  withdrawing: string;
  ipoable: string;
  btcValuation: string;
};

type BalanceDetail = {
  asset: string;
  free: string;
  locked: string;
};

type SubmitInformation = {
  side: OrderSide_LT;
  price: string;
  amount: string;
};

export {
  BookOrderType,
  OrderHistoryDetail,
  TradeHistoryDetail,
  CoinInfoDetail,
  NetworkListDetail,
  DepositDetail,
  BalanceDetail,
  SubmitInformation,
  UserAssetDetail,
};
