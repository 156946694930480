import React, { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Select from "react-select";
import {
  BalanceDetail,
  CoinInfoDetail,
  DepositDetail,
  NetworkListDetail,
} from "../../types/types";

import { COINS_INFO_URL, DEPOSIT_ADDRESS_URL } from "../../constants/urls";
import {
  CURRENTLY_SUPPORTED_NETWORK,
  DEFAULT_DEPOSIT_COIN,
} from "../../constants/constants";

const options = [
  { value: "BTC", label: "BTC", symbol: "BTC", name: "Bitcoin" },
  { value: "USDT", label: "USDT", symbol: "USDT", name: "TetherUS" },
  { value: "ETH", label: "ETH", symbol: "ETH", name: "Ethereum" },
];

const OptionLayout = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <article
      ref={innerRef}
      {...innerProps}
      className={"custom-option flex hover:bg-backgroundGray py-[10px]"}
    >
      <div className="ml-[10px] mr-[20px]">{props.data.coin}</div>
      <div className={"sub text-normalGray"}>{props.data.name} </div>
    </article>
  );
};

const networkOptions = [
  "Bitcoin",
  "BNB Beacon Chain (BEP2)",
  "BNB Smart Chain (BEP20)",
  "Ethereum (ERC20)",
  "Lightning Network",
  "BTC(SegWit)",
];

const networkAddressMock = "1LSJS2rnwYaUAetrzocY7ckMZgNnSHxuaU example";

type Props = {
  balanceMap: { [key in string]: BalanceDetail };
};

const Deposit = ({ balanceMap }: Props) => {
  const [activeNetworkIdx, setActiveNetworkIdx] = useState(0);
  const [tokenOptions, setTokenOptions] = useState<CoinInfoDetail[]>([]);
  const [chosenOption, setChosenOption] = useState<CoinInfoDetail | null>();
  const [depositDetail, setDepositDetail] = useState<DepositDetail | null>();

  const fetchCoinsInfo = async () => {
    const req = await fetch(COINS_INFO_URL);
    const data: CoinInfoDetail[] = await req.json();

    const filteredData = data.filter((tokenInfo) => {
      if (tokenInfo.depositAllEnable) {
        const filteredNetwork = filterOptions(tokenInfo.networkList);
        return filteredNetwork.length > 0;
      } else {
        return false;
      }
    });

    const newFilretedData = filteredData.map((data) => {
      const networkList = filterOptions(data.networkList);
      return {
        ...data,
        networkList: networkList,
        name: data.coin,
        label: data.coin,
      };
    });

    setTokenOptions(newFilretedData);

    // set BTC as initial deposit or withdraw
    const btcTokenOption = newFilretedData.find((option) => {
      return option.coin === DEFAULT_DEPOSIT_COIN;
    });

    setChosenOption(btcTokenOption);
  };

  const filterOptions = (options: NetworkListDetail[]): NetworkListDetail[] => {
    return options.filter((option) =>
      CURRENTLY_SUPPORTED_NETWORK.includes(option.network)
    );
  };

  const handleTokenOptionChange = (tokenOption: CoinInfoDetail | null) => {
    setChosenOption(tokenOption);
  };

  const fetchDetailForChosenOption = async () => {
    if (chosenOption) {
      const req = await fetch(
        `${DEPOSIT_ADDRESS_URL}?` +
          new URLSearchParams({
            symbol: chosenOption.coin,
            network: chosenOption.networkList[activeNetworkIdx].network,
          })
      );
      const data: DepositDetail = await req.json();
      setDepositDetail(data);
    }
  };

  const handleCopy = async () => {
    if (depositDetail) {
      await navigator.clipboard.writeText(depositDetail.address);
    }
  };

  useEffect(() => {
    if (chosenOption != null) {
      fetchDetailForChosenOption();
    }
  }, [chosenOption, activeNetworkIdx]);

  useEffect(() => {
    fetchCoinsInfo();
  }, []);

  let total =
    parseFloat(chosenOption?.free ?? "0") +
    parseFloat(chosenOption?.locked ?? "0");

  return (
    <div className="w-full px-[32px] py-[43px]">
      <div className="text-3xl font-medium mb-[30px]">Deposit</div>
      <div className="mb-[20px]">
        <div className="font-semibold text-sm">Crypto Address</div>
        <div className="border-b-[2px] border-brightGreen mt-[6px] w-[105px]"></div>
        <div className="border-b-[1px] border-normalBorder mt-[0.5px]"></div>
      </div>

      <div className="flex justify-between">
        <div>
          <div className="text-normalGray mb-[15px] text-sm">Coin</div>
          <div className="w-[550px] mb-[18px]">
            {chosenOption && (
              <Select
                defaultValue={chosenOption}
                options={tokenOptions}
                components={{ Option: OptionLayout }}
                onChange={(tokenOption) => handleTokenOptionChange(tokenOption)}
              />
            )}
          </div>
          <div>
            <div className="w-[240px] flex text-xs mb-[10px]">
              <p className="inline flex-1 text-normalGray">Total:</p>
              <p className="inline flex-1 font-semibold">
                {chosenOption && `${total} ${chosenOption.coin}`}
              </p>
            </div>

            <div className="w-[240px] flex text-xs  mb-[10px]">
              <p className="inline flex-1 text-normalGray">In order:</p>
              <p className="inline flex-1 font-semibold">
                {chosenOption && `${chosenOption.locked} ${chosenOption.coin}`}
              </p>
            </div>

            <div className="w-[240px] flex text-xs">
              <p className="inline flex-1 text-normalGray">
                Available balance:
              </p>
              <p className="inline flex-1 font-semibold">
                {chosenOption && `${chosenOption.free} ${chosenOption.coin}`}
              </p>
            </div>
          </div>
        </div>

        <div
          style={{ boxShadow: "0 0 4px rgba(0,0,0,.2)", borderRadius: 10 }}
          className="px-[20px] py-[20px] w-[460px] h-[460px]"
        >
          <p className="text-sm font-light mb-[5px]">Select network: </p>

          <div className="flex flex-wrap">
            {chosenOption &&
              chosenOption.networkList.map((network, idx) => {
                return (
                  <div
                    className={`cursor-pointer mb-[10px] mr-[10px] border-[1px] px-[10px] py-[5px] ${
                      activeNetworkIdx === idx
                        ? "border-brightGreen"
                        : "border-borderDarkDray"
                    } rounded-[4px] text-sm`}
                    onClick={() => setActiveNetworkIdx(idx)}
                  >
                    {network.name}
                  </div>
                );
              })}
          </div>

          <p className="mb-[5px] text-sm">{`${chosenOption?.coin} Address:`}</p>
          <p className="mb-[10px] text-sm text-brightGreen">
            {depositDetail?.address}
          </p>

          <div
            onClick={() => handleCopy()}
            className="cursor-pointer px-[10px] py-[5px] inline-block mb-[20px] text-sm items-center border-[1px] border-borderDarkDray"
          >
            <ContentCopyIcon />
            <p className="inline ml-[10px]">Copy Address</p>
          </div>
          <p className="mb-[10px] text-xs font-semibold">
            {`Send only ${chosenOption?.coin} to this deposit address.`}
          </p>
          <p className="text-xs">
            {`Sending coin or token other than ${chosenOption?.coin} to this address may result in
            the loss of your deposit.`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
