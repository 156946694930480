import React, { useEffect, useState } from "react";
import Chart from "./Chart";
import * as d3 from "d3";
import "./style.css";
import { parse } from "path";
import classNames from "classnames";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

type Props = {
  candleStickData: [PriceData];
  updateChartTime: (newCharTime: string) => void;
  chartTimeType: string;
};

type PriceData = {
  baseAssetVolume: string;
  close: string;
  closeTime: number;
  high: string;
  low: string;
  open: string;
  openTime: number;
  quoteAssetVolume: string;
  trades: number;
  volume: string;
};

const priceChartTimeArr = [
  "1m",
  "3m",
  "5m",
  "15m",
  "30m",
  "1h",
  "2h",
  "4h",
  "6h",
  "8h",
  "12h",
  "1d",
  "3d",
  "1w",
  "1M",
];

const CandleStickGraph = React.memo(
  ({ candleStickData, chartTimeType, updateChartTime }: Props) => {
    const chart_width = 782;
    const chart_height = 426;

    let bars_displayed = 40;
    let last_bar_displayed = 0;

    const randomOne = (weight = 1) => {
      return (Math.random() + Math.random() - 1) * weight;
    };

    const generateDataTest = () => {
      const length = Math.round(Math.random() * 90) + 10;

      // initial values
      const seed_close = Math.random() * 150 + 50;
      let previous_close = seed_close;
      let previous_volume = Math.random() * 300 + 10;
      let trend = Math.floor(Math.random() * 2) * 2 - 1;

      // calculate each bar
      let res = d3.range(length).map((item, i) => {
        const open = previous_close * (1 + randomOne(0.1));
        const close = open * (1 + randomOne(0.2) * trend);
        const high = Math.max(open, close) * (1 + randomOne(0.1));
        const low = Math.min(open, close) * (1 - randomOne(0.1));
        const volume = previous_volume * (1 + randomOne(0.5));

        previous_close = close;
        trend = Math.floor(Math.random() * 2) * 2 - 1;

        return {
          time: i,
          open,
          high,
          low,
          close,
          volume,
        };
      });
    };

    const generateData = () => {
      if (candleStickData.length <= 0) {
        return [];
      }

      const length = candleStickData.length;

      var previous_close = parseFloat(candleStickData[0].close);

      // calculate each bar
      let result = d3.range(length).map((item, i) => {
        let data = candleStickData[i];

        const open = parseFloat(data.open);
        const close = parseFloat(data.close);
        const high = parseFloat(data.high);
        const low = parseFloat(data.low);
        const volume = parseFloat(data.volume);

        previous_close = close;

        return {
          time: data.closeTime,
          open,
          high,
          low,
          close,
          volume,
        };
      });

      return result;
    };

    const [data, setData] = useState(generateData());

    const calculateTotalVolume = (data) => {
      let totalVolume = 0;

      data.forEach((element) => {
        totalVolume = Math.max(totalVolume, parseFloat(element.volume));
      });

      return totalVolume;
    };

    const setTime = (selectedTime: string) => {
      updateChartTime(selectedTime);
    };

    let chart_padding = 5;
    return (
      <div className="border-[1px] border-normalBorder">
        <div className="flex bg-backgroundGray">
          {DropdownExample({
            activePrice: chartTimeType,
            setTime: setTime,
          })}
        </div>
        <div className={`px-[${chart_padding}px]`}>
          <Chart
            data={data}
            width={chart_width - chart_padding * 2}
            height={chart_height}
            totalVolume={calculateTotalVolume(data)}
          />
        </div>
      </div>
    );
  }
);

function DropdownExample({
  activePrice,
  setTime,
}: {
  activePrice: string;
  setTime: (selectedTime: string) => void;
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {activePrice}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {priceChartTimeArr.map((timeStr) => {
              return (
                <div onClick={() => setTime(timeStr)}>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        {timeStr}
                      </a>
                    )}
                  </Menu.Item>
                </div>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default CandleStickGraph;
