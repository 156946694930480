import React from "react";

import { useNavigate } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const navigate = useNavigate();

  const navigateToPath = (path: string) => {
    navigate(`/${path}`);
  };

  return (
    <div className="bg-backgroundBlack h-[400px] flex flex-col justify-between pl-[160px] pr-[230px] pt-[60px]">
      <div className="flex justify-between ">
        <div className="flex">
          <div className="text-white mr-[115px]">
            <p className="text-lg mb-[15px]">About</p>
            <p className="text-sm mb-[10px] text-textGray">Fees Schedule</p>
            <p className="text-sm mb-[10px] text-textGray">Terms</p>
            <p className="text-sm mb-[10px] text-textGray">Privacy</p>
            <p className="text-sm mb-[10px] text-textGray">Referral</p>
          </div>
          <div className="text-white">
            <p className="text-lg mb-[15px]">Support</p>
            <a
              href="https://blockfinex.zendesk.com/hcsections/360011128932-FAQ"
              target="_blank"
              className="text-sm mb-[10px] text-textGray block"
            >
              FAQ
            </a>
            <a
              href="https://blockfinex.zendesk.com"
              target="_blank"
              className="text-sm mb-[10px] text-textGray block"
            >
              Support Center
            </a>
            <a
              href="https://blockfinex.zendesk.com/hcrequests/new"
              target="_blank"
              className="text-sm mb-[10px] text-textGray block"
            >
              Submit a Request
            </a>
            <p className="text-sm mb-[10px] text-textGray">
              API Documentation (Coming soon)
            </p>
            <p className="text-sm mb-[10px] text-textGray">
              IOS App (Coming soon)
            </p>
            <p className="text-sm mb-[10px] text-textGray">
              Android App (Coming soon)
            </p>
          </div>
        </div>

        <div className="text-white">
          <p className="text-lg mb-[15px]">Community</p>
          <div>
            <a
              href="https://twitter.com/blockfinexcom"
              target="_blank"
              className="text-sm mb-[10px] text-textGray"
            >
              <TwitterIcon className="mr-[7px]" /> twitter
            </a>
          </div>
          <div>
            <a
              href="https://t.me/blockfinexchange"
              target="_blank"
              className="text-sm mb-[10px] text-textGray"
            >
              <TelegramIcon className="mr-[7px]" /> telegram
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/blockfinex"
              target="_blank"
              className="text-sm mb-[10px] text-textGray"
            >
              <InstagramIcon className="mr-[7px]" /> instagram
            </a>
          </div>
          <div>
            <a
              href="https://medium.com/@blockfinexcom"
              target="_blank"
              className="text-sm mb-[10px] text-textGray"
            >
              medium
            </a>
          </div>
        </div>
      </div>

      <div>
        <p className="text-sm mb-[20px] text-textGray">
          Copyright @ 2021 Blockfinex All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
