import React from "react";
import { BookOrderType } from "../types/types";
import { Bid } from "binance-api-node";

type Props = {
  askBidArr: Bid[];
  token0Name: string;
  token1Name: string;
  bookOrderType: BookOrderType;
};

const AskBidBookTable = ({
  askBidArr,
  token0Name,
  token1Name,
  bookOrderType,
}: Props) => {
  var totalQuantity = 0;
  if (askBidArr.length > 0) {
    totalQuantity =
      askBidArr
        .map((item) => parseFloat(item.quantity))
        .reduce((a, b) => a + b) / askBidArr.length;
  }

  let isAsk = bookOrderType === BookOrderType.Ask;
  return (
    <table className="flex-1">
      {isAsk && (
        <thead>
          <tr className="text-normalGray text-xs">
            <th className="w-[85px] font-medium">{`Price(${token1Name})`}</th>
            <th className="w-[93px] font-medium">{`Amount(${token0Name})`}</th>
            <th className="w-[100px] font-medium">{`Total(${token1Name})`}</th>
          </tr>
        </thead>
      )}
      <tbody>
        {askBidArr.map(({ price, quantity }) => {
          let floatPrice = parseFloat(price);
          let floatQuantity = parseFloat(quantity);
          let total = floatPrice * parseFloat(quantity);

          let percentageQuantity = floatQuantity / totalQuantity;

          let percentageQuantityOutOf100 = percentageQuantity * 100;
          let bgColor =
            bookOrderType === BookOrderType.Ask ? "#fbeaf1" : "#f1f6e9";
          return (
            <tr
              className="text-xs text-normalBlack"
              style={{
                background: `linear-gradient(to left, ${bgColor} ${percentageQuantityOutOf100}%, #ffffff ${0}%)`,
              }}
            >
              <th
                className={`text-left font-medium w-[85px] ${
                  isAsk ? "text-normalRed" : "text-normalGreen"
                }`}
              >
                {floatPrice.toFixed(2)}
              </th>
              <th className="text-right font-medium w-[93px]">
                {floatQuantity.toFixed(5)}
              </th>
              <th className="text-right font-medium w-[100px]">
                {total.toFixed(2)}
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AskBidBookTable;
