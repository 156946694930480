import React, { useState } from "react";

import logo from "../assets/blockfinex_logo.png";
import { useNavigate } from "react-router-dom";
import CustomizedDialogs from "../components/common/CustomDialog";
import { ENABLE_FUNCTIONS } from "../configs/configs";
import { useAuth } from "../provider/authProvider";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";

const AvatarIcon = ({ handleLogout }: { handleLogout: () => void }) => {
  const [avatarEl, setAvatarEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [invisible, setInvisible] = React.useState(false);

  const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const [notifyEl, setNotifyEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };

  const handleNotifyOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNotifyEl(e.currentTarget);
    if (!invisible) {
      handleBadgeVisibility();
    }
  };

  const handleNotifyClose = () => {
    setNotifyEl(null);
  };

  const open = Boolean(avatarEl);
  const id = open ? "simpe-popover" : undefined;

  const notifyOpen = Boolean(notifyEl);
  const notifyId = notifyOpen ? "simpe-notify" : undefined;
  return (
    <div>
      <Stack direction="row" spacing={1}>
        <Button aria-describedby={id} onClick={handleAvatarClick}>
          <Avatar></Avatar>
          <KeyboardArrowDownIcon />
        </Button>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Avatar" />
            </ListItemButton>
          </ListItem>
          <Divider />

          <Divider />
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText onClick={handleLogout} primary="Log out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

const Header = () => {
  const { isUserAuthenticated, logout } = useAuth();
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const navigate = useNavigate();

  const navigateToPath = (path: string) => {
    navigate(`/${path}`);
  };

  const handleWarningDialog = (status: boolean) => {
    setIsWarningDialogOpen(status);
  };

  const handleNotSupportedFunctions = () => {
    setIsWarningDialogOpen(true);
  };

  const handleNavigation = (path: string) => {
    if (ENABLE_FUNCTIONS) {
      navigateToPath(path);
    } else {
      handleNotSupportedFunctions();
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleLogoClick = () => {
    navigate("/home");
  };

  return (
    <>
      <CustomizedDialogs
        open={isWarningDialogOpen}
        setOpen={handleWarningDialog}
      />

      <div className="bg-black h-[64px] flex items-center justify-between px-[30px]">
        <div className="text-white text-sm flex space-x-[20px] items-center">
          <img
            src={logo}
            height={24}
            width={120}
            alt="blockfinex logo"
            className="cursor-pointer"
            onClick={handleLogoClick}
          />
          <p className="cursor-pointer" onClick={handleNotSupportedFunctions}>
            Markets
          </p>
          <p className="cursor-pointer" onClick={() => navigateToPath("")}>
            Exchange
          </p>
        </div>
        <div className="text-white text-sm flex space-x-[20px] items-center ">
          {isUserAuthenticated ? (
            <>
              <div
                className="cursor-pointer"
                onClick={() => handleNavigation("dashboard")}
              >
                Dashboard
              </div>

              <AvatarIcon handleLogout={handleLogout} />
            </>
          ) : (
            <>
              <div
                className="cursor-pointer"
                onClick={() => handleNavigation("login")}
              >
                Login
              </div>
              <div
                className="cursor-pointer"
                onClick={() => handleNavigation("register")}
              >
                Register
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
