import React from "react";
import MyOrderHistorySection from "../MyOrderHistory";
import { OrderHistoryDetail } from "../../types/types";

type Props = {
  orderHistoryList: [OrderHistoryDetail];
};

const OrderHistoryContent = ({ orderHistoryList }: Props) => {
  return (
    <div className="w-full px-[32px] py-[43px]">
      <MyOrderHistorySection
        orderHistoryList={orderHistoryList}
        title={
          <div className="text-3xl font-medium mb-[30px]">Order History</div>
        }
        height={350}
      />
    </div>
  );
};

export default OrderHistoryContent;
