import React, { useEffect, useState } from "react";
import BuySellSection from "./BuySellSection";
import { BalanceDetail, SubmitInformation } from "../types/types";
import { OrderSide_LT } from "binance-api-node";
import { NEW_ORDER_URL } from "../constants/urls";
import { ENABLE_FUNCTIONS } from "../configs/configs";
import MarketSection from "./MarketSection";

type Props = {
  currentTradePrice: string;
  token0: string;
  token1: string;
  balances: BalanceDetail[];
  onOrderSubmitted: (type: OrderSide_LT) => void;
  handleNotEnabledFunctions: () => void;
};

type MarketType = "LIMIT" | "MARKET";

const ExchangeSection = React.memo(
  ({
    currentTradePrice,
    token0,
    token1,
    balances,
    onOrderSubmitted,
    handleNotEnabledFunctions,
  }: Props) => {
    const [balanceObject, setBalanceObject] = useState<{
      [x in string]: BalanceDetail;
    }>({});

    useEffect(() => {
      const balanceObject = {};

      for (const balance of balances) {
        balanceObject[balance.asset] = balance;
      }

      setBalanceObject(balanceObject);
    }, [balances]);

    const [marketType, setMarketType] = useState<MarketType>("LIMIT");

    const token0Balance = balanceObject[token0] ?? null;
    const token1Balance = balanceObject[token1] ?? null;

    const handleSubmit = async (submitInfo: SubmitInformation) => {
      if (!ENABLE_FUNCTIONS) {
        handleNotEnabledFunctions();
        return;
      }

      try {
        const resp = await fetch(
          `${NEW_ORDER_URL}?` +
            new URLSearchParams({
              symbol: token0 + token1,
              side: submitInfo.side.toUpperCase(),
              type: "LIMIT",
              price: submitInfo.price,
              quantity: submitInfo.amount,
              timeInForce: "GTC",
            }),
          {
            method: "POST",
          }
        );

        const data = await resp.json();

        if (data) {
          onOrderSubmitted(submitInfo.side);
        }
      } catch (e) {
        console.log("error ", e);
      }
    };

    const handleMarketSubmit = async (submitInfo: SubmitInformation) => {
      if (!ENABLE_FUNCTIONS) {
        handleNotEnabledFunctions();
        return;
      }

      try {
        const additionalParam: any =
          submitInfo.side.toUpperCase() === "BUY"
            ? { quoteOrderQty: submitInfo.amount }
            : { quantity: submitInfo.amount };

        const resp = await fetch(
          `${NEW_ORDER_URL}?` +
            new URLSearchParams({
              symbol: token0 + token1,
              side: submitInfo.side.toUpperCase(),
              type: "MARKET",
              ...additionalParam,
            }),
          {
            method: "POST",
          }
        );

        const data = await resp.json();

        if (data) {
          onOrderSubmitted(submitInfo.side);
        }
      } catch (e) {
        console.log("error ", e);
      }
    };

    const renderLimitType = () => {
      return (
        <div className="flex bg-backgroundGray">
          <div className="flex-1 py-[16px] px-[26px]">
            <BuySellSection
              currentTradePrice={currentTradePrice}
              side="buy"
              token0Symbol={token0}
              token1Symbol={token1}
              balance={token1Balance ? token1Balance.free : "0"}
              balanceTitle={token1}
              onSubmit={handleSubmit}
            />
          </div>
          <div className="flex-1 py-[16px] px-[26px] border-l-[1px] border-normalBorder">
            <BuySellSection
              currentTradePrice={currentTradePrice}
              side="sell"
              token0Symbol={token0}
              token1Symbol={token1}
              balance={token0Balance ? token0Balance.free : "0"}
              balanceTitle={token0}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      );
    };

    const renderMarketType = () => {
      return (
        <div className="flex bg-backgroundGray">
          <div className="flex-1 py-[16px] px-[26px]">
            <MarketSection
              currentTradePrice={currentTradePrice}
              side="buy"
              token0Symbol={token0}
              token1Symbol={token1}
              balance={token1Balance ? token1Balance.free : "0"}
              balanceTitle={token1}
              onSubmit={handleMarketSubmit}
            />
          </div>
          <div className="flex-1 py-[16px] px-[26px] border-l-[1px] border-normalBorder">
            <MarketSection
              currentTradePrice={currentTradePrice}
              side="sell"
              token0Symbol={token0}
              token1Symbol={token1}
              balance={token0Balance ? token0Balance.free : "0"}
              balanceTitle={token0}
              onSubmit={handleMarketSubmit}
            />
          </div>
        </div>
      );
    };

    const handleClickLimit = () => {
      setMarketType("LIMIT");
    };

    const handleClickMarket = () => {
      setMarketType("MARKET");
    };

    return (
      <div className="border-[1px] border-normalBorder">
        <div className="text-sm font-small py-[6px] px-[12px] border-b-[1px] border-normalBorder">
          Exchange
        </div>
        <div>
          <div className="text-sm px-[12px] py-[6px] flex space-x-[4px] bg-backgroundGray border-b-[1px] border-normalBorder">
            <div
              onClick={() => handleClickLimit()}
              className="cursor-pointer bg-white px-[6px] border-[1px] border-normalBorder"
            >
              Limit
            </div>
            <div
              onClick={() => handleClickMarket()}
              className="cursor-pointer bg-white px-[6px] border-[1px] border-normalBorder"
            >
              Market
            </div>
          </div>

          {marketType === "LIMIT" ? renderLimitType() : renderMarketType()}
        </div>
      </div>
    );
  }
);

export default ExchangeSection;
