import React, { useEffect, useRef } from "react";

let tvScriptLoadingPromise;

const TradingViewWidget = ({ symbol }) => {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById("technical-analysis-chart-demo") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          container_id: "technical-analysis-chart-demo",
          width: "100%",
          height: "100%",
          autosize: true,
          symbol: symbol,
          interval: "D",
          timezone: "exchange",
          theme: "light",
          style: "1",
          withdateranges: true,
          hide_side_toolbar: false,
          allow_symbol_change: true,
          save_image: false,
          studies: [
            // "ROC@tv-basicstudies",
            // "StochasticRSI@tv-basicstudies",
            // "MASimple@tv-basicstudies",
          ],
          show_popup_button: true,
          popup_width: "1000",
          popup_height: "650",
          locale: "en",
          datafeed: {
            type: "binance",
            overrides: {
              "paneProperties.showSymbolSearchInput": false,
            },
          },
          disabled_features: [
            "use_localstorage_for_settings",
            "link_to_tradingview",
            "volume_force_overlay",
            "header_interval_dialog_button",
            //, "show_dialog_on_snapshot_ready"
            "study_templates",
            "chart_property_page_trading",
            "chart_crosshair_menu",
            "hide_last_na_study_output",
            "header_symbol_search",
            "header_settings",
            "symbol_search_hot_key",
            "context_menus",
            "edit_buttons_in_legend",
            "display_market_status",
          ],
        });

        document.getElementsByClassName(
          "tradingview-widget-copyright"
        )[0].style.visibility = "hidden";
      }
    }
  }, [symbol]);

  return (
    <div
      className="tradingview-widget-container"
      style={{ height: "100%", width: "100%" }}
    >
      <div
        id="technical-analysis-chart-demo"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      />
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default TradingViewWidget;
