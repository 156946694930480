import React, { useEffect, useState } from "react";
import { CoinInfoDetail, UserAssetDetail } from "../../types/types";
import { COINS_INFO_URL, USER_ASSETS_URL } from "../../constants/urls";
import { FilterOutlined } from "@mui/icons-material";
import { filterOptions } from "../../utils/utils";
import { parse } from "path";

type Props = {
  cryptoBalanceList: [CryptoBalanceDetail];
};

type CryptoBalanceDetail = {
  coinSymbol: string;
  coinImageUrl: string;
  coinName: string;
  coinTotalBalance: string;
  coinAvailableBalance: string;
  coinInOrder: string;
  coinBtcValue: string;
};

const BalancesContent = ({ cryptoBalanceList }: Props) => {
  const [tokenBalanceList, setTokenBalanceList] = useState<CoinInfoDetail[]>(
    []
  );

  const [userAssets, setUserAssets] = useState<UserAssetDetail[]>([]);

  const fetchUserAsset = async () => {
    const resp = await fetch(USER_ASSETS_URL);
    const userAssets = await resp.json();

    setUserAssets(userAssets);
  };

  const fetchCoinsInfo = async () => {
    const req = await fetch(COINS_INFO_URL);
    const data: CoinInfoDetail[] = await req.json();

    const filteredData = data.filter((tokenInfo) => {
      if (tokenInfo.depositAllEnable) {
        const filteredNetwork = filterOptions(tokenInfo.networkList);
        return filteredNetwork.length > 0;
      } else {
        return false;
      }
    });

    const newFilretedData = filteredData.map((data) => {
      const networkList = filterOptions(data.networkList);
      return {
        ...data,
        networkList: networkList,
        label: data.coin,
      };
    });

    newFilretedData.sort((a, b) => {
      let totalA = parseFloat(a.free) + parseFloat(a.locked);
      let totalB = parseFloat(b.free) + parseFloat(b.locked);
      return totalB - totalA;
    });

    setTokenBalanceList(newFilretedData);
  };

  useEffect(() => {
    fetchCoinsInfo();
    fetchUserAsset();
  }, []);

  const totalBtcBalance = userAssets.reduce(
    (acc, curVal) => acc + parseFloat(curVal.btcValuation),
    0
  );

  return (
    <div className="w-full px-[32px] py-[43px]">
      <div className="flex space-x-[20px] mb-[32px]">
        <div className="text-3xl font-medium">Balances</div>

        <div className="border-r-[1px] border-normalBorder"></div>

        <div>
          <div className="text-normalGray text-sm font-light">
            Fiat currency Balances
          </div>
          <div className="text-sm font-normal">0 BTC</div>
        </div>

        <div>
          <div className="text-normalGray text-sm font-light">Crypto</div>
          <div className="text-sm font-normal">{`${parseFloat(
            totalBtcBalance.toFixed(10)
          )} BTC`}</div>
        </div>
      </div>

      <div className="border-b-[1px] border-normalBorder mb-[20px]" />

      <div>
        <div className="text-xs font-light text-normalGray mb-[6px]">
          Crypto
        </div>

        <div className="">
          <table className="flex-1">
            <thead className="bg-backgroundGray border-normalBorder">
              <tr className="text-normalGray text-xs text-left ">
                <th className="py-[10px] px-[6px] w-[130.33px] font-medium">{`Coin`}</th>
                <th className="py-[5px] px-[6px] w-[130.33px] font-medium">{`Name`}</th>
                <th className="py-[5px] px-[6px] w-[130.33px] font-medium">{`Total Balance`}</th>
                <th className="py-[5px] px-[6px] w-[130.33px] font-medium">{`Available Balance`}</th>
                <th className="py-[5px] px-[6px] w-[130.33px] font-medium">{`In Order`}</th>
                <th className=" py-[5px] px-[6px] w-[130.33px] font-medium">{`BTC value`}</th>
                <th className=" py-[5px] px-[6px] w-[320px] font-medium">{`Action`}</th>
              </tr>
            </thead>
            {tokenBalanceList.length > 0 && (
              <tbody className="border-collapse w-full">
                {tokenBalanceList.map((crypto) => {
                  const { coin, free, name, locked } = crypto;

                  const coinImageUrl = "";
                  const coinBtcValue = 0;

                  return (
                    <tr className="text-xs text-left">
                      <th className="py-[10px] px-[4px] font-medium flex items-center">
                        {coinImageUrl && (
                          <img
                            width={18}
                            height={18}
                            src={coinImageUrl}
                            className="inline mr-[18px]"
                          />
                        )}
                        {`${coin}`}
                      </th>
                      <th className="px-[4px] font-medium">{`${name}`}</th>
                      <th className="px-[4px] font-medium">{`${
                        parseFloat(free) + parseFloat(locked)
                      }`}</th>
                      <th className="px-[4px] font-medium">{`${free}`}</th>
                      <th className="px-[4px] font-medium">{`${locked}`}</th>
                      <th className="px-[4px] font-medium">{`--`}</th>
                      <th className="px-[4px] font-medium">
                        <p className="cursor-pointer inline mr-[30px] text-brightGreen">
                          Deposit
                        </p>
                        <p className="cursor-pointer inline text-brightGreen">
                          Withdraw
                        </p>
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default BalancesContent;
