import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

type Props = {
  selectActivePage: (newActivePage: string) => void;
  dropdownWalletItems: [string];
  dropdownOrdersItems: [string];
  activeIndex: number;
  setActiveIndex: (number) => void;
  indexToTitle: { [key in number]: string };
};

const Sidebar = ({
  selectActivePage,
  dropdownWalletItems,
  dropdownOrdersItems,
  activeIndex,
  setActiveIndex,
  indexToTitle,
}: Props) => {
  const [isWalletDropdownOpen, setIsWalletDropdownOpen] = useState(false);
  const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);

  return (
    <div className="flex h-screen bg-gray-100">
      <nav className="bg-white w-64">
        <ul>
          <li
            className={`py-3 px-4 hover:bg-gray-200 hover:cursor-pointer hover:bg-backgroundSelected flex items-center  ${
              activeIndex === 0
                ? "border-l-[5px] border-brightGreen bg-backgroundSelected"
                : ""
            }`}
            onClick={() => {
              setActiveIndex(0);
            }}
          >
            <PersonIcon className="mr-[6px]" /> Dashboard
          </li>
          <li className="relative">
            <button
              className="flex block justify-between text-left w-full py-3 px-4 hover:bg-gray-200 hover:cursor-pointer hover:bg-backgroundGray"
              onClick={() => setIsWalletDropdownOpen(!isWalletDropdownOpen)}
            >
              <div>
                <AccountBalanceWalletIcon className="mr-[2px]" /> Wallet
              </div>
              <ArrowDropDownIcon style={{ color: "#999999" }} />
            </button>
            {isWalletDropdownOpen && (
              <ul className="bg-white top-0 mt-2">
                {dropdownWalletItems.map((item, index) => (
                  <li
                    key={index}
                    className={`hover:bg-gray-100 hover:cursor-pointer hover:bg-backgroundSelected ${
                      activeIndex === index + 1 && activeIndex != 0
                        ? "border-l-[5px] border-brightGreen backgroundSelected"
                        : ""
                    }`}
                    onClick={() => setActiveIndex(index + 1)}
                  >
                    <div className="py-2 pl-[20px]">
                      {indexToTitle[index + 1]}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>

          <li className="relative">
            <button
              className="flex block justify-between text-left w-full py-3 px-4 hover:cursor-pointer hover:bg-backgroundSelected"
              onClick={() => setIsOrderDropdownOpen(!isOrderDropdownOpen)}
            >
              <div>
                <ReceiptLongIcon className="mr-[2px]" /> Orders
              </div>
              <ArrowDropDownIcon style={{ color: "#999999" }} />
            </button>
            {isOrderDropdownOpen && (
              <ul className="bg-white top-0 mt-2">
                {dropdownOrdersItems.map((item, index) => (
                  <li
                    key={index}
                    className={`hover:bg-gray-100 hover:cursor-pointer hover:bg-backgroundSelected ${
                      activeIndex === index + 5 && activeIndex != 0
                        ? "border-l-[5px] border-brightGreen bg-backgroundSelected"
                        : ""
                    }`}
                    onClick={() => setActiveIndex(index + 5)}
                  >
                    <div className="py-2 pl-[20px]">
                      {indexToTitle[index + 5]}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
