import React, { useState } from "react";
import img from "../assets/background_blockfinex.png";

import fastTransactionImg from "../assets/fast_transaction.png";
import liquidityImg from "../assets/liquidity.png";

import quickOnboarding from "../assets/quick_onboarding.png";
import tradeAllCrypto from "../assets/trade_all_crypto.png";
import lowTradingFee from "../assets/low_trading_fees.png";
import simpleToUse from "../assets/simple_to_use.png";
import sellingPoint1 from "../assets/mainpage_selling_point_1.png";
import sellingPoint2 from "../assets/mainpage_selling_point_2.png";
import sellingPoint3 from "../assets/mainpage_selling_point_3.png";
import sellingPoint4 from "../assets/mainpage_selling_point_4.png";

import Chip from "@mui/material/Chip";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";
import { useNavigate } from "react-router-dom";
import { ENABLE_FUNCTIONS } from "../configs/configs";
import CustomizedDialogs from "../components/common/CustomDialog";
import useProgressiveImage from "../hooks/useProgressiveImage";

const MainPage = () => {
  const navigate = useNavigate();
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const loaded = useProgressiveImage(img);

  const handleBuyCrypto = () => {
    navigate("/");
  };

  const handleGetStarted = () => {
    navigate("/");
  };

  const handleLogin = () => {
    if (ENABLE_FUNCTIONS) {
      navigate("/login");
    } else {
      handleNotSupportedFunctions();
    }
  };

  const handleRegister = () => {
    if (ENABLE_FUNCTIONS) {
      navigate("/register");
    } else {
      handleNotSupportedFunctions();
    }
  };

  const handleNotSupportedFunctions = () => {
    setIsWarningDialogOpen(true);
  };

  const handleWarningDialog = (status: boolean) => {
    setIsWarningDialogOpen(status);
  };

  return (
    <>
      <CustomizedDialogs
        open={isWarningDialogOpen}
        setOpen={handleWarningDialog}
      />
      <div
        style={{
          backgroundImage: `url(${loaded})`,
          background: "black",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
        }}
        className="bg-no-repeat text-white flex flex-col justify-center mb-[50px]"
      >
        <div className="ml-[120px]">
          <div className="flex items-center space-x-[10px] mb-[25px]">
            <Chip
              label="New"
              variant="outlined"
              style={{
                color: "#3ccb7c",
                backgroundColor: "white",
                borderColor: "#3ccb7c",
              }}
            />

            <div>Trade crypto in minutes</div>
          </div>

          <div className="mb-[20px]">
            <h1 className="text-5xl mb-[10px]">Trade Lightning Fast in</h1>
            <h1 className="text-5xl text-brightGreen">Seconds</h1>
          </div>

          <div className="mb-[30px]">
            Industry Leading security and 24-hour support from blockfinex
          </div>

          <div
            style={{
              background: "linear-gradient(90deg,#2a9662 0,#3dcd89 99.77%)",
            }}
            className={`text-black inline cursor-pointer text-lg font-medium text-white text-center py-[14px] px-[45px] mt-[10px] rounded-md`}
            onClick={handleBuyCrypto}
          >
            {`Buy Crypto`}
          </div>
        </div>
      </div>

      <div className="flex justify-center space-x-[20px]">
        <img
          src={sellingPoint1}
          loading="lazy"
          alt="global social payment application"
          width={266}
        />
        <img
          src={sellingPoint2}
          loading="lazy"
          alt="your encrypted messager app"
          width={266}
        />
        <img
          src={sellingPoint3}
          loading="lazy"
          alt="binance compatible with blockfinex"
          width={266}
        />
        <img
          src={sellingPoint4}
          loading="lazy"
          alt="20% commision everytime your friend make a trade"
          width={266}
        />
      </div>
      <div className="flex flex-col items-center mt-[80px] mb-[80px]">
        <h1 className="text-3xl mb-[30px]">
          Multi Currency Trading anytime you want
        </h1>

        <CryptoCurrencyMarket
          colorTheme="dark"
          width="100vw"
          height={400}
        ></CryptoCurrencyMarket>
      </div>
      <div className="bg-lightGreen pb-[100px] mb-[80px]">
        <div className="flex items-center space-y-[100px] space-x-[80px]">
          <div className="flex-1 flex justify-end ">
            <div>
              <p className="text-textGray mb-[10px]">Blazing speed</p>
              <p className="text-2xl font-bold mb-[20px]">Fast Transaction</p>
              <p className="mb-[20px]">
                Trade in microseconds with the fastest matching engine in crypto
              </p>
              <p
                className="font-semibold text-greenMainButton cursor-pointer"
                onClick={handleGetStarted}
              >
                {"Get Started >"}
              </p>
            </div>
          </div>
          <div className="flex-1">
            <img src={fastTransactionImg} alt="fast transaction" width={520} />
          </div>
        </div>

        <div className="flex items-center space-y-[100px] space-x-[100px]">
          <div className="flex-1 flex justify-end ">
            <img src={liquidityImg} alt="liqudity" width={470} />
          </div>
          <div className="flex-1">
            <div>
              <p className="text-textGray mb-[10px]">Filled Order Books</p>
              <p className="text-2xl font-bold mb-[20px]">Liquidity</p>
              <p className="mb-[20px]">
                We provide market leading liquidity so you can trade on-demand
                from the moment you sign up
              </p>
              <p
                className="font-semibold text-greenMainButton cursor-pointer"
                onClick={handleGetStarted}
              >
                {"Get Started >"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-[80px] pl-[200px] pr-[200px] mb-[100px]">
        <div className="flex-1">
          <img src={quickOnboarding} width={60} className="mb-[8px]" />
          <p className="text-xl font-semibold mb-[15px]">
            Quick Onboarding Process
          </p>
          <p>Create your account and onboard yourself in minutes</p>
        </div>
        <div className="flex-1">
          <img src={tradeAllCrypto} width={60} className="mb-[8px]" />
          <p className="text-xl font-semibold mb-[15px]">Trade All Cryptos</p>
          <p>Trade all top crypto pairs in the market with us</p>
        </div>
        <div className="flex-1">
          <img src={lowTradingFee} width={60} className="mb-[8px]" />
          <p className="text-xl font-semibold mb-[15px]">Low Trading Fees</p>
          <p>Trade with low fees compared to industry leading competitors</p>
        </div>
      </div>
      <div className="flex justify-center mb-[70px]">
        <img src={simpleToUse} className="px-[200px]" />
      </div>
      <div className="bg-greenMainButton mx-[200px] px-[45px] py-[40px] flex rounded-lg justify-between items-center  mb-[100px]">
        <div className="text-white text-semibold text-3xl ">
          <p>Start Trading</p>
          <p>Cryptocurrencies today</p>
        </div>

        <div className="flex space-x-[20px]">
          <Button
            variant="outlined"
            style={{
              backgroundColor: "transparent",
              textTransform: "capitalize",
              color: "white",
              borderColor: "white",
              height: 50,
              width: 120,
              padding: 20,
            }}
            onClick={handleLogin}
          >
            Log in
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "white",
              color: "black",
              textTransform: "capitalize",
              height: 50,
              padding: 20,
            }}
            onClick={handleRegister}
          >
            Register
          </Button>
        </div>
      </div>
    </>
  );
};

export default MainPage;
