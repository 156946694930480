import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

import HeaderNew from "../pages/HeaderNew.tsx";

export const ProtectedRoute = () => {
  const { isUserAuthenticated } = useAuth();
  console.log("isUserAuthenticated protected route ", isUserAuthenticated);

  // Check if the user is authenticated
  if (!isUserAuthenticated) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};
