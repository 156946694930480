import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  DEPOSIT_HISTORY_URL,
  WITHDRAW_HISTORY_URL,
} from "../../constants/urls";

type Props = {
  withdrawDepositHistoryList: [WithdrawDepositHistoryDetail];
};

type WithdrawDepositHistoryDetail = {
  status: string;
  coinSymbol: string;
  amount: string;
  time: number;
  Information: string;
};

// {
//   "id": "769800519366885376",
//   "amount": "0.001",
//   "coin": "BNB",
//   "network": "BNB",
//   "status": 0,
//   "address": "bnb136ns6lfw4zs5hg4n85vdthaad7hq5m4gtkgf23",
//   "addressTag": "101764890",
//   "txId": "98A3EA560C6B3336D348B6C83F0F95ECE4F1F5919E94BD006E5BF3BF264FACFC",
//   "insertTime": 1661493146000,
//   "transferType": 0,
//   "confirmTimes": "1/1",
//   "unlockConfirm": 0,
//   "walletType": 0
// },

type DepositHistoryDetail = {
  id: string;
  amount: string;
  coin: string;
  network: string;
  status: number;
  insertTime: string;
};

type WithdrawHistoryDetail = {
  id: string;
  amount: string;
  coin: string;
  network: string;
  status: number;
  completeTime: string;
};

enum Mode {
  Deposit,
  Withdraw,
}

const DepositAndWithdrawalHistory = ({ withdrawDepositHistoryList }: Props) => {
  const [transferMode, setTransferMode] = useState<Mode>(Mode.Deposit);

  const [depositHistoryList, setDepositHistoryList] = useState<
    DepositHistoryDetail[]
  >([]);
  const [withdrawHistoryList, setWithdrawHistoryList] = useState<
    WithdrawHistoryDetail[]
  >([]);

  const fetchDepositHistory = async () => {
    const req = await fetch(DEPOSIT_HISTORY_URL);
    const historyList = await req.json();

    setDepositHistoryList(historyList);
  };

  const fetchWithdrawHistory = async () => {
    const req = await fetch(WITHDRAW_HISTORY_URL);
    const historyList = await req.json();

    setWithdrawHistoryList(historyList);
  };

  useEffect(() => {
    fetchDepositHistory();
    fetchWithdrawHistory();
  }, []);

  useEffect(() => {
    if (transferMode === Mode.Deposit) {
      fetchDepositHistory();
    } else {
      fetchWithdrawHistory();
    }
  }, [transferMode]);

  const shownList =
    transferMode === Mode.Deposit ? depositHistoryList : withdrawHistoryList;

  return (
    <div className="w-full px-[32px] py-[43px]">
      <div className="text-3xl font-medium mb-[30px]">
        Deposit and Withdrawal History
      </div>

      <div className="flex space-x-[60px] mb-[20px]">
        <div className="text-sm flex space-x-[10px] text-normalGray">
          <p>Transfer:</p>

          <p
            className={`${
              transferMode === Mode.Deposit
                ? "border-b-[2px] border-brightGreen text-normalBlack"
                : ""
            } cursor-pointer`}
            onClick={() => setTransferMode(Mode.Deposit)}
          >
            Deposit
          </p>

          <div className="border-l-[1px] border-normalBorder" />
          <p
            className={`${
              transferMode === Mode.Withdraw
                ? "border-b-[2px] border-brightGreen text-normalBlack"
                : ""
            } cursor-pointer`}
            onClick={() => setTransferMode(Mode.Withdraw)}
          >
            Withdraw
          </p>
        </div>

        <div className="text-sm flex space-x-[10px] text-normalGray">
          <p>Currency:</p>

          <p
            className={
              "border-b-[2px] border-brightGreen text-normalBlack cursor-pointer"
            }
          >
            Crypto
          </p>
        </div>
      </div>

      <div>
        <table className="flex-1">
          <thead className="bg-backgroundGray border-normalBorder">
            <tr className="text-normalGray text-xs text-left  border-[1px] border-normalBorder">
              <th className="py-[10px] px-[6px] w-[137.86px] font-medium">{`Status`}</th>
              <th className="py-[5px] px-[6px] w-[137.86px] font-medium">{`Coin`}</th>
              <th className="py-[5px] px-[6px] w-[137.86px] font-medium">{`Amount`}</th>
              <th className="py-[5px] px-[6px] w-[137.86px] font-medium">{`Time`}</th>
              <th className="py-[5px] px-[6px] w-[500.86px] font-medium">{`Information`}</th>
            </tr>
          </thead>
          {shownList.length > 0 && (
            <tbody className="border-collapse w-full">
              {shownList.map((historyDetail) => {
                const { status, coin, amount, insertTime } = historyDetail;

                const momentTimestamp = moment(insertTime);
                let strTime =
                  transferMode === Mode.Deposit
                    ? momentTimestamp.format("MM-DD HH:mm:ss")
                    : historyDetail.completeTime;

                let information = "";

                return (
                  <tr className="text-xs text-left border-[1px] border-normalBorder">
                    <th className="py-[10px] px-[4px] font-medium flex items-center">
                      {`${status}`}
                    </th>
                    <th className="px-[4px] font-medium">{`${coin}`}</th>
                    <th className="px-[4px] font-medium">{`${amount}`}</th>
                    <th className="px-[4px] font-medium">{`${strTime}`}</th>
                    <th className="px-[4px] font-medium">{`${information}`}</th>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default DepositAndWithdrawalHistory;
