import React, { useEffect, useState } from "react";
import EmptyImage from "../../assets/no_record_image.png";
import moment from "moment";
import { TradeHistoryDetail } from "../../types/order";
import { MY_TRADES_URL } from "../../constants/urls";

type Props = {
  tradeHistoryList: [TradeHistoryDetail];
};

const TradeHistoryContent = ({ tradeHistoryList }: Props) => {
  const [my24HoursOrders, setMy24HoursOrders] = useState([]);

  const fetchMyTrades = async () => {
    // let symbol = activeTokenPairs.token0 + activeTokenPairs.token1;
    let symbol = "USDT" + "BTC";

    const resp = await fetch(
      MY_TRADES_URL +
        new URLSearchParams({
          symbol: symbol,
        })
    );

    const data = await resp.json();

    setMy24HoursOrders(data);
  };

  useEffect(() => {
    fetchMyTrades();
  }, []);

  return (
    <div
      className="w-full  px-[32px] py-[43px] flex flex-col"
      style={{ height: 350 }}
    >
      <div className="text-3xl font-medium mb-[30px]">Trade History</div>

      <div
        className="border-normalBorder border-[1px] h-[267px] flex flex-col flex-1"
        style={{ overflow: "scroll" }}
      >
        <div className="" style={{ overflow: "scroll" }}>
          <table className="w-full flex-1" style={{ position: "sticky" }}>
            <thead
              className="bg-backgroundGray border-normalBorder"
              style={{ position: "sticky" }}
            >
              <tr className="text-normalGray text-xs text-left ">
                <th className="py-[5px] px-[6px] font-medium">{`Time`}</th>
                <th className="border-l-[1px] border-normalBorder py-[10px] px-[6px] font-medium">{`Pair`}</th>
                <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] font-medium">{`Side`}</th>
                <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] font-medium">{`Price`}</th>
                <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] font-medium">{`Filled`}</th>
                <th className="border-l-[1px] border-normalBorder py-[5px] px-[6px] font-medium">{`Trading Fees`}</th>
                <th className="border-l-[1px] border-normalBorder  py-[5px] px-[6px] font-medium">{`Total`}</th>
              </tr>
            </thead>
            {tradeHistoryList.length > 0 && (
              <tbody
                className="border-collapse w-full"
                style={{ overflow: "scroll" }}
              >
                {tradeHistoryList.map((tradeHistory) => {
                  const {
                    date,
                    pair,
                    side,
                    price,
                    filled,
                    tradingFees,
                    total,
                  } = tradeHistory;

                  const momentTimestamp = moment(date);
                  let strTime = momentTimestamp.format("MM-DD HH:mm:ss");

                  return (
                    <tr className="text-normalGray text-xs text-left ">
                      <th className="py-[10px] px-[4px] font-medium">{`${strTime}`}</th>
                      <th className="px-[4px] font-medium">{`${pair}`}</th>
                      <th
                        className={`px-[4px] font-medium ${
                          side == "BUY" ? "text-normalGreen" : "text-normalRed"
                        }`}
                      >{`${side}`}</th>
                      <th className="px-[4px] font-medium">{`${price}`}</th>
                      <th className="px-[4px] font-medium">{`${filled}`}</th>
                      <th className="px-[4px] font-medium">{`${tradingFees}`}</th>
                      <th className="px-[4px] font-medium">{`${total}}`}</th>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>

        {tradeHistoryList.length === 0 && (
          <div className="flex justify-center flex-1">
            <div className="flex flex-col justify-center align-center">
              <div className="flex justify-center">
                <img src={EmptyImage} alt="empty image" />
              </div>
              <div>No records found</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TradeHistoryContent;
