import React, { useEffect, useRef, useState } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { SubmitInformation } from "../types/types";

type Props = {
  currentTradePrice: string;
  side: "buy" | "sell";
  token0Symbol: string;
  token1Symbol: string;
  balanceTitle: string;
  balance: string;
  onSubmit: (SubmitInformation) => void;
};

const BuySellSection = ({
  currentTradePrice,
  side,
  token0Symbol,
  token1Symbol,
  balanceTitle,
  balance,
  onSubmit,
}: Props) => {
  const perncentages = [0.25, 0.5, 0.75, 1.0];
  const title = side === "buy" ? "Buy" : "Sell";
  const [price, setPrice] = useState(currentTradePrice);
  const [amount, setAmount] = useState("");
  const [total, setTotal] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setPrice(currentTradePrice);
  }, [currentTradePrice]);

  const handleSubmit = () => {
    onSubmit({ price, amount, side });
  };

  const handlePriceChange = (e) => {
    let price = e.target.value;
    setPrice(price);
    if (amount) {
      let total = parseFloat(price ?? 0) * parseFloat(amount ?? 0);
      setTotal(String(total ?? 0));
    }
  };

  const handleAmountChange = (amount) => {
    setAmount(amount);
    if (price) {
      let total = parseFloat(price ?? 0) * parseFloat(amount ?? 0);
      setTotal(String(total ?? 0));
    }
  };

  const handleTotalChange = (total) => {
    setTotal(total);
    if (price) {
      let floatPrice = parseFloat(price) ?? 1;
      let amount = parseFloat(total) / floatPrice;
      setAmount(String(amount ?? 0));
    }
  };

  const handlePercentageClick = (percentage) => {
    console.log("side ", side);
    console.log("balance testing", balance);
    if (side === "sell") {
      handleAmountChange(percentage * parseFloat(balance));
    } else {
      handleTotalChange(percentage * parseFloat(balance));
    }
  };

  return (
    <div className="">
      <div className="flex justify-between">
        <div className="font-semibold mb-[14px]">{`${title} ${token0Symbol}`}</div>
        <div className="flex text-xs">
          <AccountBalanceWalletIcon
            style={{ height: 16, width: 16 }}
            className="mr-[2px]"
          />
          <p className="mr-[2px]">{parseFloat(balance)}</p>
          <p>{balanceTitle}</p>
        </div>
      </div>

      <div className="flex flex-col space-y-[0px]">
        <div className="flex justify-between">
          <div className="text-xs">Price: </div>
          <div className="w-[256px] border-[1px] border-normalBorder flex flex-wrap  relative bg-white items-center mb-[10px]">
            <input
              ref={inputRef}
              value={price}
              className="text-sm leading-normal flex-1 border-0 px-3 relative self-center outline-none"
              onChange={handlePriceChange}
              type="number"
            />
            <div className="flex">
              <span className="text-xs text-normalGray font-light flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600">
                {token1Symbol}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-xs">Amount: </div>
          <div className="w-[256px] border-[1px] border-normalBorder flex flex-wrap  relative bg-white items-center mb-[10px]">
            <input
              value={amount}
              className="text-sm leading-normal flex-1 border-0 px-3 relative self-center outline-none"
              onChange={(e) => handleAmountChange(e.target.value)}
              type="number"
            />
            <div className="flex">
              <span className="text-xs text-normalGray font-light flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600">
                {token0Symbol}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-xs"></div>
          <div className="w-[256px] justify-between space-x-[4px] flex flex-wrap relative items-center mb-[10px]">
            {perncentages.map((percentage) => {
              return (
                <div
                  className="bg-white border-[1px] border-normalBorder text-sm flex-1 text-center cursor-pointer"
                  onClick={() => handlePercentageClick(percentage)}
                >
                  {percentage * 100}%
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-xs">Total: </div>
          <div className="w-[256px] border-[1px] border-normalBorder flex flex-wrap  relative bg-white items-center">
            <input
              value={total}
              className="text-sm leading-normal flex-1 border-0 px-3 relative self-center outline-none"
              onChange={(e) => handleTotalChange(e.target.value)}
              type="number"
            />
            <div className="flex">
              <span className="text-xs text-normalGray font-light flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600">
                {token1Symbol}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`border-2 ${
          side === "buy" ? "bg-normalGreen" : "bg-normalRed"
        } text-white text-sm font-light text-center py-[6px] mt-[30px] cursor-pointer`}
        onClick={handleSubmit}
      >
        {`${title} ${token0Symbol}`}
      </div>
    </div>
  );
};

export default BuySellSection;
