import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IS_USER_AUTHENTICATED, LOGOUT_URL } from "../constants/urls";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // State to hold the authentication token

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    localStorage.getItem("isAuthenticated")
  );

  const checkIfUserAuthenticated = async () => {
    // try {d
    const resp = await axios.get(IS_USER_AUTHENTICATED, {
      withCredentials: true,
    });

    if (resp?.data?.isAuthenticated) {
      setIsUserAuthenticated(true);
      localStorage.setItem("isAuthenticated", true);
      return true;
    } else {
      setIsUserAuthenticated(false);
      localStorage.setItem("isAuthenticated", false);
      return false;
    }
  };

  const logout = async () => {
    // try {
    const resp = await axios.get(LOGOUT_URL, {
      withCredentials: true,
    });
    console.log("resp", resp.data);

    if (resp.data) {
      setIsUserAuthenticated(false);
      localStorage.setItem("isAuthenticated", false);
    }
    // } catch (e) {
    //   console.log("error logout", e);
    // }
  };

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      isUserAuthenticated,
      checkIfUserAuthenticated,
      logout,
    }),
    [isUserAuthenticated]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
